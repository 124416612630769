import React from "react";
import { graphql } from 'gatsby';
import TemplateWrapper from "../components/template-wrapper";
import Link from "gatsby-link";
import "../styles/breakpoints.less"
import "../styles/colors.less"
import "../styles/background-colors.less"
import "../styles/blog-typography.less"
import "../styles/flex.less"
import "../styles/blogcontent.less"

export default function cstBlogsIndex ({ data }) {
  let { nodes: posts } = data.allMarkdownRemark;
  console.log("Posts length: " + posts.length);
  const accumulation = "";

  return (
    <div className="palette--cst-site"> 
    <TemplateWrapper headerImages={data.allFile.nodes} accumulation = {accumulation}  category={""} >   
      {posts
        .filter(post => post.frontmatter.title.length > 0)
        .map( ( post ) => {
          return (
            <div className="blog-post-preview" key={post.id}>
              <h2>
              <Link to={post.fields.link} dangerouslySetInnerHTML={{__html: post.frontmatter.title}}/>
              </h2>
              {/* <h4>Written by {post.frontmatter.author} on {post.frontmatter.date}</h4>
              <h4>Tags: {post.frontmatter.tags}</h4>   */}
              <div dangerouslySetInnerHTML={{__html: post.html}} />
            </div>
          );
        })}
    </TemplateWrapper>
    </div>
  );
}

export const pageQuery = graphql`
  query cstBlogsIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "info" } } },
      sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        id
        html
        fields {
          link
        }
        frontmatter {
          date
          title
          author
          categories
          tags
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/science-society/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( layout: FULL_WIDTH )
        }
      } 
    }
  }
`;
